@import '~antd/dist/antd.css';

/* @import '~antd/dist/antd.less';  */
/* @import 'App.less';  */

.App {
  text-align: center;
}
input { outline: none; }
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-form-item {
    margin: 0;
}

.list-wrapper {
    margin: 1rem;
}

.login-banner {
  background-image: url(/banner.jpg);
  height: 30%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 50px;
  margin-bottom: 20px;
  width: 100%;
  background-color: black;

}
.login-wrapper {
  background-color: black;
  height: 100vh;
}


.ant-menu-item:hover > a {
  color: white !important;
}

.ant-menu-horizontal > .ant-menu-item-selected > a{
  color: #9266f6;

}
.ant-tabs-nav .ant-tabs-tab-active,
.ant-menu-horizontal > .ant-menu-item-selected {
  color: #9266f6;
  border-bottom: 2px solid rgb(146 102 246);
}
.anticon-arrow-left > svg {
  margin-right: 1rem;
}
.ant-menu-item > a:hover {
  color: black;
}
.ant-tabs-ink-bar,
.ant-tabs-nav .ant-tabs-tab:hover,
.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #9266f6;
}
.anticon {
  color: #9266f6;
}
.ant-tabs-nav .ant-tabs-tab:hover,
.ant-menu-horizontal > .ant-menu-item > a:hover{
  color: #FFFFFF;
}
.ant-menu-horizontal > .ant-menu-submenu:hover{
  color: #9266f6;
}
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active:hover {
  border-bottom: 2px solid #9266f6;
}
.ant-btn.ant-btn-primary{
  background-color: #9266f6;
  border-color: #FFFFFF;
  color: #FFFFFF;
}
.ant-menu-item > a {
  color: #9266f6;
}
.ant-btn.ant-btn-primary:hover {
  background-color: #FFFFFF;
  color: #9266f6;
  border-color: #9266f6;
}
.ant-menu-horizontal > .ant-menu-item:hover > a > .anticon {
  color: #FFFFFF;
}
.delete-lookup {
  color: red;
}
a,
.ant-table-filter-dropdown-link,
.ant-dropdown-menu-item-selected,
.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter{
  color: #9266f6 !important;
}
.ant-calendar-ok-btn,
.ant-table-filter-dropdown-link{
  background-color: #fff !important;
  color: #9266f6 !important;
  border-color: #9266f6 !important;
}

.ant-calendar-ok-btn:hover{
  color: #fff;
  background-color: #9266f6;
}

.ant-pagination-prev:hover > .ant-pagination-item-link,
.ant-pagination-next:hover > .ant-pagination-item-link,
.ant-pagination-item:hover,
.ant-pagination-item:hover >a,
.ant-pagination-item.ant-pagination-item-active>a,
.ant-pagination-item.ant-pagination-item-active,
.ant-pagination-item-active,
.ant-btn {
  color: #9266f6;
  border-color: #9266f6;
}

.ant-btn:hover{
  background-color: #9266f6;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.ant-btn.ant-btn-danger{
  color: #fff;
}

.ant-btn.ant-btn-danger {
  border: none;
}

.ant-btn.ant-btn-danger:hover {
  background-color: #ff7875;
}

.ant-input-search .ant-input-affix-wrapper:hover,
.ant-input-search .ant-input-affix-wrapper:active,
.ant-input:focus,
.ant-input:hover,
.ant-input-search:hover,
.ant-input-search:focus {
  border-color: #9266f6 !important;
}
.ant-menu-horizontal > .ant-menu-item > a {
  color: #9266f6;
}

.ant-tabs-bar {
  margin-bottom: 0;
}

.ant-spin-dot-item {
  background-color: #9266f6;
}
.ant-checkbox-inner:hover,
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #9266f6;
  border-color: #9266f6;
}
#event_create_form_startStopTime {
  width: 100% !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #9266f6;
}

.ant-radio-inner::after {
  background-color: #9266f6;
}

.ant-radio-checked::after {
  border: 1px solid #9266f6;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: #9266f6;
}

.eventRange,
.eventTime {
  min-width: 100%;
}